import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { useModal } from '@components/modals/use-modal'
import { CustomFormGroup } from '@components/form-controls/custom-form-group'
import { SelectedPlanBanner } from '@modules/holiday-benefit/employee-invitation/selected-plan-banner'
import { HolidayBenefitInvitationRulesSection } from '@modules/holiday-benefit/employee-invitation/rules'
import { HolidayBenefitInvitation } from '@modules/holiday-benefit/employee-invitation/models'
import { FormPasswordInput } from '@components/form-controls/form-password-input'
import { FadeInView } from '@components/fade-in-view'

interface Props {
  onLoginClick: () => void
  onPreviousStepClick: () => void
  onSubmit: () => Promise<void>
  benefitInvitation: HolidayBenefitInvitation
}

export const HolidayBenefitInvitationAccountCreation = ({
  onLoginClick,
  onSubmit,
  onPreviousStepClick,
  benefitInvitation,
}: Props): React.ReactNode => {
  const [showClientAppDetailsModal] = useModal('ClientAppDetailsModal')

  return (
    <FadeInView>
      <div className="my-3 mt-5 bg-light-blue rounded-3 p-lg-5 p-3">
        <h5 className="text-holiday-benefit-tertiary font-xxl fw-bold text-center">
          Uzupełnij dane <br />
          utwórz Konto Gościa <span className="text-nowrap">Holiday Park & Resort</span>
        </h5>
        <p className="text-center mt-4 mb-3 pb-0 font-lg mx-auto col-lg-9 col-12 text-darker-gray">
          Pozwoli Ci to na zarządzanie wybranym benefitem i tworzenie rezerwacji w ośrodkach
          <span className="text-nowrap"> Holiday Park & Resort</span>.
        </p>

        <div className="d-flex gap-4 justify-content-center mb-4">
          <IconWithText
            text="Czym jest Konto Gościa?"
            iconClassName="uil-info-circle font-xxl me-1"
            className="text-center fw-bold text-muted justify-content-center"
            textClassName="font-md fw-semibold"
            onClick={showClientAppDetailsModal}
          />

          <IconWithText
            text="Zaloguj się"
            iconClassName="uil-user font-xxl me-1"
            className="text-center fw-bold text-muted justify-content-center"
            textClassName="font-md fw-semibold"
            onClick={onLoginClick}
          />
        </div>

        <SelectedPlanBanner onEditClick={onPreviousStepClick} benefitInvitation={benefitInvitation} />

        <CustomFormGroup
          formGroupProps={{ className: 'package-wholesale__data-input mt-4' }}
          inputName="first_name"
          label="Imię:"
          formLabelProps={{ className: 'col-4 fw-semibold' }}
          formControlProps={{
            placeholder: 'Wpisz imię',
            className: 'text-holiday-benefit-secondary col-8',
          }}
        />

        <CustomFormGroup
          formGroupProps={{ className: 'package-wholesale__data-input' }}
          inputName="last_name"
          label="Nazwisko:"
          formLabelProps={{ className: 'col-4 fw-semibold' }}
          formControlProps={{
            placeholder: 'Wpisz nazwisko',
            className: 'text-holiday-benefit-secondary col-8',
          }}
        />

        <CustomFormGroup
          formGroupProps={{ className: 'package-wholesale__data-input' }}
          inputName="email_create"
          label="Adres e-mail:"
          formLabelProps={{ className: 'col-4 fw-semibold' }}
          formControlProps={{
            type: 'email',
            placeholder: 'Wpisz adres e-mail',
            className: 'text-holiday-benefit-secondary col-8',
          }}
        />

        <IconWithText
          className="col-sm-8 col-12 ms-auto mt-2 mb-3"
          iconClassName="uil-info-circle font-xxl me-1 text-holiday-benefit-tertiary align-self-start"
          text={
            <span className="font-md text-darker-gray">
              <strong>Pamiętaj!</strong> Sprawdź poprawność adresu e-mail ponieważ będzie to Twój login do Konta Gościa.
            </span>
          }
        />

        <FormPasswordInput
          passwordHintClassName="col-sm-8 col-12 ms-auto d-block"
          label="Hasło:"
          inputName="password_create"
          formGroupProps={{ className: 'package-wholesale__data-input' }}
          formLabelProps={{ className: 'fw-bold col-4' }}
          formControlProps={{ type: 'tel', className: 'col-8', placeholder: 'Wpisz hasło' }}
        />

        <CustomFormGroup
          label="Numer telefonu:"
          inputName="phone"
          formGroupProps={{ className: 'package-wholesale__data-input' }}
          formLabelProps={{ className: 'fw-bold col-4' }}
          formControlProps={{ type: 'tel', className: 'col-8', placeholder: 'Wpisz numer telefonu' }}
        />

        <CustomFormGroup
          label={<span className="text-nowrap">Adres zamieszkania:</span>}
          inputName="street"
          formGroupProps={{ className: 'package-wholesale__data-input' }}
          formLabelProps={{ className: 'fw-bold col-4' }}
          formControlProps={{ type: 'tel', className: 'col-8', placeholder: 'Wpisz ulicę' }}
        />
        <div className="col-sm-8 col-12 ms-auto">
          <div className="row">
            <CustomFormGroup
              inputName="postcode"
              formGroupProps={{ className: 'package-wholesale__data-input col-5 pe-0' }}
              formControlProps={{ type: 'tel', placeholder: 'Kod pocztowy' }}
            />
            <CustomFormGroup
              inputName="city"
              formGroupProps={{ className: 'package-wholesale__data-input col-7 ps-2' }}
              formControlProps={{ type: 'tel', placeholder: 'Wpisz miasto' }}
            />
          </div>
        </div>
      </div>
      <HolidayBenefitInvitationRulesSection onSubmit={onSubmit} benefitInvitation={benefitInvitation} />
    </FadeInView>
  )
}
