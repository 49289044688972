import * as React from 'react'
import { GastroDynamicOfferExpirationTime } from '@modules/gastro-generic/details/gastro-dynamic/gastro-dynamic-offer-expiration-time'
import { GastroGenericProductDetails } from '@modules/gastro-generic/models'
import { useGastroProductMultipliers } from '@modules/gastro-generic/details/gastro-dynamic/use-gastro-product-multipliers'

interface Props {
  gastroCardDetails: GastroGenericProductDetails
}

export const GastroCardDynamicDeferredPaymentBanner = ({ gastroCardDetails }: Props): React.ReactNode => {
  const { currentOffer } = useGastroProductMultipliers(gastroCardDetails)

  return (
    <div className="gastro-card-details__dynamic__deferred-payment-banner">
      <div>
        <strong className="d-block font-xxl text-dark">Kup już dziś, zapłać za 30 dni:</strong>
        <div className="d-flex flex-md-row flex-column align-items-center text-dark my-3 my-md-0">
          <span className="font-lg">
            Śpiesz się, {currentOffer ? 'wartość dopłaty zmniejszy się za:' : 'wartość dopłaty wkrótce się zmniejszy!'}
          </span>
          {currentOffer && (
            <GastroDynamicOfferExpirationTime gastroProductMultiplier={currentOffer} spinnerClassName="text-dark" />
          )}
        </div>
      </div>
      <img src={require('@assets/images/paypo-logo.svg')} alt="PayPo" height={30} />
    </div>
  )
}
