import * as React from 'react'
import { formatPrice } from '@helpers/price-helper'
import { useFormContext, useWatch } from 'react-hook-form'
import { EndlessHolidayFormInputs } from '@modules/endless-holiday/details'
import { useEndlessHolidayTotalPrice } from '@modules/endless-holiday/details/side-card/use-total-price'

interface Props {
  showDiscountedPrice: boolean
}

export const EndlessHolidaySideCardSummaryPrice = ({ showDiscountedPrice }: Props): React.ReactNode => {
  const { control } = useFormContext<EndlessHolidayFormInputs>()
  const products = useWatch({ control, name: 'products' })

  const { totalPrice } = useEndlessHolidayTotalPrice(products, showDiscountedPrice)

  return (
    <>
      <hr className="mb-0" />
      <li className="font-lg p-3 subscription-variant__price-side-card__summary">
        <span className="font-500">Łącznie do zapłaty</span>
        <span className="col-5">
          <span className="text-end font-500 d-block">{formatPrice(totalPrice)}</span>
        </span>
      </li>
    </>
  )
}
