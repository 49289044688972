import * as React from 'react'
import { formatPrice } from '@helpers/price-helper'
import { EndlessHolidayProduct } from '@modules/endless-holiday/models'
import { IconWithText } from '@components/icon-with-text'

interface Props {
  subscriptionProduct: EndlessHolidayProduct
  amount: number
}

export const DepositSideCardGastroCardSummaryRow = ({ subscriptionProduct, amount }: Props): React.ReactNode => (
  <div className="d-flex justify-content-between align-items-center opacity-75 font-sm">
    <div className="font-xs font-500 col-8 pe-4">
      <IconWithText
        text="Kaucja zwrotna"
        iconClassName="uil-check font-md me-1"
        textClassName="text-truncate font-xs"
      />
    </div>
    <div className="col-1">{amount}x</div>
    <div className="col-3 text-end font-xs font-500">{formatPrice(subscriptionProduct.deposit_amount)}</div>
  </div>
)
