import * as React from 'react'
import { Spinner } from '@components/spinner'

interface Props {
  isLoading: boolean
  text: string
  loadingText?: string
  className: string
  disabled: boolean
  onClick: () => void
}

export const ButtonWithLoader = ({
  onClick,
  disabled,
  loadingText = 'Proszę czekać...',
  isLoading,
  text,
  className,
}: Props): JSX.Element => {
  return (
    <button className={className} onClick={onClick} disabled={disabled}>
      {isLoading ? (
        <>
          <Spinner className="text-white spinner-border-sm" /> <span className="ms-1">{loadingText}</span>
        </>
      ) : (
        text
      )}
    </button>
  )
}
