import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { AppHeaderContent } from '@components/app-header-content'
import { toDefaultDateFormat } from '@helpers/date-formatter'
import { EndlessHolidayConfirmationDetails } from '@modules/endless-holiday/models'

interface Props {
  onScrollToBill: () => void
  productDetails: EndlessHolidayConfirmationDetails
}

export const EndlessHolidayConfirmationHeaderSectionContent = ({
  onScrollToBill,
  productDetails,
}: Props): React.ReactNode => (
  <AppHeaderContent
    title={
      <>
        Podsumowanie zamówienia <br /> {productDetails.number}
      </>
    }
    subtitle={
      <span className="font-lg">
        <strong className="d-block">Dziękujemy za złożenie zamówienia</strong>
        <span>w dniu {toDefaultDateFormat(productDetails.created)}</span>
      </span>
    }
  >
    <div className="mt-4 d-flex justify-content-center justify-content-sm-start flex-wrap col-12">
      <IconWithText
        text="Zobacz podsumowanie"
        iconClassName="uil-plus font-xxxl me-2"
        className="text-gold font-size-xl cursor-pointer fw-bold"
        onClick={onScrollToBill}
      />
    </div>
  </AppHeaderContent>
)
