import * as React from 'react'
import { EndlessHolidayProduct } from '@modules/endless-holiday/models'
import { useFormContext, useWatch } from 'react-hook-form'
import { EndlessHolidayFormInputProduct, EndlessHolidayFormInputs } from '@modules/endless-holiday/details'
import { ProductVariantOptionBox } from '@modules/endless-holiday/details/products-selection/product-variant/options/product-variant-option-box'
import { formatPriceShort } from '@helpers/price-helper'

interface GroupedProducts {
  currentGastroProduct: EndlessHolidayFormInputProduct | null
  restProducts: EndlessHolidayFormInputProduct[]
}

interface Props {
  gastroProducts: EndlessHolidayProduct[]
  subscriptionProduct: EndlessHolidayProduct
  children: React.ReactNode
}

export const ProductVariantOptions = ({ gastroProducts, subscriptionProduct, children }: Props): React.ReactNode => {
  const { setValue, control } = useFormContext<EndlessHolidayFormInputs>()
  const [products, accommodation] = useWatch({ control, name: ['products', 'accommodation'] })

  const handleClick = (gastroProduct: EndlessHolidayProduct) => () => {
    const { currentGastroProduct, restProducts } = products.reduce(
      (acc, product) => {
        if (product.related_product === subscriptionProduct.id) {
          return { ...acc, currentGastroProduct: product }
        }

        return { ...acc, restProducts: [...acc.restProducts, product] }
      },
      { restProducts: [], currentGastroProduct: null },
    ) as GroupedProducts

    setValue('products', [
      ...restProducts,
      { product: gastroProduct, amount: currentGastroProduct?.amount ?? 1, related_product: subscriptionProduct.id },
    ])
  }

  const isChecked = (gastroProduct: EndlessHolidayProduct) =>
    products.some(
      product => product.product.id === gastroProduct.id && product.related_product === subscriptionProduct.id,
    )

  return (
    <div className="text-darker-gray endless-holiday__voucher-selection ms-auto col-lg-11 col-12">
      {children}
      <p className="font-500 font-lg mb-1 text-center text-lg-start">Wybierz wariant vouchera gastronomicznego:</p>
      <div className="row gap-3 gap-lg-0">
        {gastroProducts.map((product, index) => (
          <ProductVariantOptionBox
            inactiveLabel={index === gastroProducts.length - 1 && accommodation === 'apartment' && <InactiveLabel />}
            isChecked={isChecked(product)}
            key={product.id}
            product={product}
            onClick={handleClick(product)}
            text={
              index === gastroProducts.length - 1 ? (
                <div className="lh-sm">
                  Otrzymasz <span className="font-500">{formatPriceShort(product.price_brutto)}</span> do wykorzystania,{' '}
                  <br />
                  oraz dostęp do <strong>lokali specjalnych.</strong>
                </div>
              ) : (
                <div className="lh-sm">
                  Otrzymasz <span className="font-500">{formatPriceShort(product.price_brutto)}</span> do wykorzystania.{' '}
                  <br />
                  Kwota minimalna.
                </div>
              )
            }
          />
        ))}
      </div>
    </div>
  )
}

const InactiveLabel = () => (
  <div className="position-absolute start-50 top-50 translate-middle border rounded p-2 bg-light-gray font-xs font-500 text-nowrap text-darker-gray pe-none">
    opcja niedostępna dla apartamentów
  </div>
)
