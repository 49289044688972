import { asDecimal, sumDecimalArray } from '@helpers/utils'
import { EndlessHolidayFormInputProduct } from '@modules/endless-holiday/details'
import { useEndlessHolidayAddedProducts } from '@modules/endless-holiday/details/side-card/use-endless-holiday-products'

interface Response {
  totalPrice: number
}

export const useEndlessHolidayTotalPrice = (
  products: EndlessHolidayFormInputProduct[],
  showDiscountedPrice: boolean,
): Response => {
  const { subscriptionProducts, gastroCardProducts } = useEndlessHolidayAddedProducts(products)

  const totalPrice = sumDecimalArray(
    subscriptionProducts.map(products => {
      const gastroProduct = gastroCardProducts.find(
        gastroProduct => gastroProduct.related_product === products.product.id,
      )

      const subscriptionsPrice = asDecimal(products.amount).mul(
        asDecimal(showDiscountedPrice ? products.product.first_price_brutto : products.product.price_brutto),
      )

      const depositPrice = asDecimal(products.amount).mul(products.product.deposit_amount)

      const gastroPrice = gastroProduct
        ? asDecimal(products.amount).mul(
            asDecimal(
              showDiscountedPrice ? gastroProduct.product.first_price_brutto : gastroProduct.product.price_brutto,
            ),
          )
        : asDecimal(0)

      return subscriptionsPrice.add(gastroPrice).add(depositPrice)
    }),
  ).toNumber()

  return {
    totalPrice,
  }
}
