import * as React from 'react'
import { EndlessHolidayConfirmationGastroCard } from '@modules/endless-holiday/models'
import { getYear } from 'date-fns'
import { parseISODate } from '@helpers/date-formatter'
import { asDecimal, sumDecimalArray } from '@helpers/utils'
import { formatPrice, formatPriceShort } from '@helpers/price-helper'
import clsx from 'clsx'

interface Props {
  gastroCards: EndlessHolidayConfirmationGastroCard[]
  year: string
}

export const EndlessHolidayConfirmationBillGastroCards = ({ gastroCards, year }: Props): React.ReactNode => {
  const subscriptionGastroCards = gastroCards.filter(
    gastroCard => getYear(parseISODate(gastroCard.active_after)) === parseInt(year, 10),
  )

  const groupedByAmount = subscriptionGastroCards.reduce((acc, gastroCard) => {
    const amount = asDecimal(gastroCard.declared_sell_price).toNumber()
    if (acc[amount]) {
      return { ...acc, [amount]: [...acc[amount], gastroCard] }
    }
    return { ...acc, [amount]: [gastroCard] }
  }, {}) as { [key: string]: EndlessHolidayConfirmationGastroCard[] }

  const getTotalPrice = (gastroCards: EndlessHolidayConfirmationGastroCard[]) =>
    sumDecimalArray(gastroCards.map(gastroCard => asDecimal(gastroCard.declared_sell_price))).toNumber()

  if (!subscriptionGastroCards.length) return null

  return (
    <div className="mt-3">
      {Object.entries(groupedByAmount).map(([amount, gastroCards], index) => (
        <div
          key={amount}
          className={clsx('d-flex justify-content-between align-items-center text-darker-gray', {
            'mt-1': index !== 0,
          })}
        >
          <div className="fw-bold">
            {gastroCards.length > 1 ? 'Vouchery gastronomiczne' : 'Voucher gastronomiczny'} {formatPriceShort(amount)}{' '}
            {gastroCards.length > 1 ? `x${gastroCards.length}` : ''}
          </div>
          <div className="fw-bold">{formatPrice(getTotalPrice(gastroCards))}</div>
        </div>
      ))}
    </div>
  )
}
