import * as React from 'react'
import { SideCard } from '@components/side-card'
import { EndlessHolidayConfirmationDetails } from '@modules/endless-holiday/models'
import { ExpirationTimeLabel } from '@components/expiration-time-label'

interface Props {
  productDetails: EndlessHolidayConfirmationDetails
  isPaid: boolean
}

export const EndlessHolidayConfirmationSideCard = ({ productDetails, isPaid }: Props): React.ReactNode => (
  <SideCard
    className="d-lg-block d-none"
    image={
      <img
        src={require('@assets/images/resorts/cieplice.jpg')}
        alt="Holiday Park & Resort"
        width="100%"
        height={300}
        className="subscription-variant__price-side-card__image"
      />
    }
  >
    {productDetails.required_payment_date && !isPaid && (
      <ExpirationTimeLabel
        expirationDate={productDetails.required_payment_date}
        messageText={<>pozostało na opłacenie zamówienia {productDetails.number}</>}
        descriptionText="Po tym czasie zamówienie zostanie anulowane."
      />
    )}
  </SideCard>
)
