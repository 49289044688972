import * as React from 'react'
import { HolidayBenefitBenefitsPlans } from '@modules/holiday-benefit/common/holiday-benefit-benefits-plans'

interface Props {
  children: React.ReactNode
}

export const HolidayBenefitEmployeeBenefitsPlans = React.forwardRef(
  ({ children }: Props, ref: React.RefObject<HTMLDivElement>): React.ReactNode => (
    <HolidayBenefitBenefitsPlans
      ref={ref}
      forEmployee
      title="Wybierz plan"
      subtitle="swojego benefitu"
      description="Wybierz plan swojego benefitu. Zdecyduj czy Twój pobyt odbędzie się w Domku czy Apartamencie i ciesz się wypoczynkiem dwa razy w roku."
      apartmentContent={<ApartmentCardContent />}
      houseContent={<HouseCardContent />}
      apartmentImage={require('@assets/images/apartment.webp')}
      houseImage={require('@assets/images/house.webp')}
    >
      {children}
    </HolidayBenefitBenefitsPlans>
  ),
)

const ApartmentCardContent = () => (
  <div className="text-darker-gray font-xl">
    <p>
      Każdego roku otrzymasz <strong>6-dniowy</strong> oraz <strong>3-dniowy pobyt w 4-osobowych apartamentach</strong>{' '}
      sieci resortów <span className="text-nowrap">Holiday Park & Resort</span> o{' '}
      <strong>łącznej wartości 2691 zł.</strong> Dodatkowo w prezencie <strong>otrzymasz 3-dniowy pobyt</strong> w
      apartamencie tuż po przystąpieniu do benefitu.
    </p>
    <span className="font-500">Zobacz jak wyglądają nasze apartamenty</span>
  </div>
)

const HouseCardContent = () => (
  <div className="text-darker-gray font-xl">
    <p>
      Każdego roku otrzymasz <strong>6-dniowy</strong> oraz <strong>3-dniowy pobyt w 9-osobowych domkach</strong> sieci
      resortów <span className="text-nowrap">Holiday Park & Resort</span> o <strong>łącznej wartości 2691 zł.</strong>{' '}
      Dodatkowo w prezencie <strong>otrzymasz 3-dniowy pobyt</strong> w domku tuż po przystąpieniu do benefitu.
    </p>
    <span className="font-500">Zobacz jak wyglądają nasze domki</span>
  </div>
)
