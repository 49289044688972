import * as React from 'react'
import { ProductsVariantsYear } from '@modules/endless-holiday/details/products-selection/product-variant/product-variants-year'
import { EndlessHolidayDetails, EndlessHolidayProduct } from '@modules/endless-holiday/models'

interface GroupedProducts {
  [year: string]: EndlessHolidayProduct[]
}

interface Props {
  productDetails: EndlessHolidayDetails
}

export const ProductsVariantsYears = ({ productDetails }: Props): React.ReactNode => {
  const groupedSubscriptionByYears: GroupedProducts = productDetails.product_package_set.products.reduce(
    (acc, product) => {
      if (!product.stay_in_years || !product.stay_in_years.length || product.kind !== 'subscription') return acc

      const productYear = product.stay_in_years[0].toString()

      const appendProduct = (previous = []) => ({ ...acc, [productYear]: [...previous, product] })
      const isYearAlreadyInAcc = Object.keys(acc).includes(productYear)
      return isYearAlreadyInAcc ? appendProduct(acc[productYear]) : appendProduct()
    },
    {},
  )

  const gastroProducts = productDetails.product_package_set.products.filter(
    product => product.kind === 'gastro_card_product_package_endless_holiday',
  )

  return (
    <div>
      {Object.entries(groupedSubscriptionByYears).map(([year, products]) => (
        <ProductsVariantsYear
          key={year}
          year={year}
          products={products}
          gastroProducts={gastroProducts}
          showDiscountedPrice={productDetails.can_use_first_price_brutto}
        />
      ))}
    </div>
  )
}
