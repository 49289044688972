import 'core-js/stable'
import 'regenerator-runtime/runtime'
import '@iconscout/unicons/css/line.css'

import '@assets/styles/app.scss'
import { NavigationPath } from '@models/routes'
import store from '@store/index'
import * as React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom'
import StartupView from './startup'
import * as smoothscroll from 'smoothscroll-polyfill'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { PackageWholesaleDetailsView } from '@modules/package-wholesale/details'
import { GastroCardDetailsView } from '@modules/gastro-card/details'
import { SubscriptionDetailsView } from '@modules/subscription/details'
import { VipDetailsView } from '@modules/vip/details'
import { ReservationDetailsView } from '@modules/reservation/details'
import { PaymentSuccess } from '@components/payment-operation-results/payment-success'
import { PaymentFailure } from '@components/payment-operation-results/payment-failure'
import { PaymentSelectionView, ProductKinds } from '@components/payments/payment-selection-view'
import { PaymentProcessing } from '@components/payment-operation-results/payment-processing'
import { WebSocketHandler } from '@components/web-socket-handler'
import { ClientWebSocketHandler } from '@components/web-socket-handler/client-web-socket-handler'
import TagManager from 'react-gtm-module'
import { SubscriptionContractView } from '@modules/subscription-contract'
import { NotFound } from '@modules/404'
import { ModalComponent } from '@components/modals/modal-component'
import { OperationSuccess } from '@components/payment-operation-results/operation-success'
import { getPackageWholesalePath } from '@helpers/routes'
import { useGtm } from '@hooks/gtm/use-gtm'
import { GastroGenericDetailsView } from '@modules/gastro-generic/details'
import { SubscriptionWithBenefitsDetailsView } from '@modules/subscription-with-benefit/details'
import { GoHolidayDetailsView } from '@modules/go-holiday/details'
import { ZooBorysewCodesDeactivationView } from '@modules/codes-deactivation/zoo-borysew-codes-deactivation-view'
import { SuntagoCodesDeactivationView } from '@modules/codes-deactivation/suntago-codes-deactivation-view'
import { AcquisitionOfConsentsView } from '@modules/acquisition-of-consents'
import { BookingOptionsView } from '@modules/booking-options/details'
import { RodoAgreementsView } from '@modules/rodo-agreements'
import { RodoAgreementsSuccessView } from '@modules/rodo-agreements/success/success-page'
import { RodoAgreementsConfirmationView } from '@modules/rodo-agreements/confirmation/confirmation-page'
import { BocianVoucherView } from '@modules/bocian-vouchers'
import { HolidayBenefitInvitationView } from '@modules/holiday-benefit/employee-invitation'
import { HolidayBenefitView } from '@modules/holiday-benefit/employer-landing-page'
import { HolidayBenefitEmployeeView } from '@modules/holiday-benefit/employee-landing-page'
import { SubscriptionBookingImprovementView } from '@modules/subscription-booking-improvement'
import { EndlessHolidayDetailsView } from '@modules/endless-holiday/details'
import { EndlessHolidayConfirmationView } from '@modules/endless-holiday/confirmation'

smoothscroll.polyfill()

if (process.env.PRODUCTS_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.PRODUCTS_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.005,
  })
}

if (process.env.GOOGLE_TAG_MANAGER) {
  TagManager.initialize({ gtmId: process.env.GOOGLE_TAG_MANAGER })
}

const AppContainer = (props): JSX.Element => {
  const location = useLocation()
  const { trackPage } = useGtm()

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    trackPage(location.pathname)
  }, [location.pathname])

  return props.children
}

interface State {
  loadFailed: boolean
}

export default class AppView extends React.Component<{}, State> {
  private packageWholesalePath = getPackageWholesalePath()

  constructor(props: {}) {
    super(props)
  }

  public render(): React.ReactNode {
    return (
      <Provider store={store}>
        <StartupView>
          <BrowserRouter basename="/">
            <AppContainer>
              <ModalComponent />
              <WebSocketHandler />
              <ClientWebSocketHandler />
              <Routes>
                <Route path={NavigationPath.ReservationDetails} element={<ReservationDetailsView />} />
                <Route path={NavigationPath.SubscriptionContractDetails} element={<SubscriptionContractView />} />
                <Route path={NavigationPath.GoHolidayDetails} element={<GoHolidayDetailsView />} />
                <Route
                  path={NavigationPath.SubscriptionContractDetailsFallback}
                  element={<SubscriptionContractView />}
                />
                <Route path={NavigationPath.SubscriptionDetails} element={<SubscriptionDetailsView />} />
                <Route path={NavigationPath.SubscriptionImprovement} element={<SubscriptionBookingImprovementView />} />
                <Route
                  path={NavigationPath.SubscriptionWithBenefitsDetails}
                  element={<SubscriptionWithBenefitsDetailsView type="WITH_BENEFIT" />}
                />
                <Route
                  path={NavigationPath.SubscriptionWithBenefits20Details}
                  element={<SubscriptionWithBenefitsDetailsView type="WITH_BENEFIT_20" />}
                />
                <Route path={NavigationPath.GastroCardDetails} element={<GastroCardDetailsView />} />
                <Route
                  path={NavigationPath.GastroCouponDetails}
                  element={<GastroGenericDetailsView kind={ProductKinds.GASTRO_COUPON} />}
                />
                <Route
                  path={NavigationPath.GastroCardBoostDetails}
                  element={<GastroGenericDetailsView kind={ProductKinds.GASTRO_CARD_BOOST} />}
                />
                <Route path={NavigationPath.VipDetails} element={<VipDetailsView />} />
                <Route path={NavigationPath.PaymentSelectionView} element={<PaymentSelectionView />} />
                <Route path={this.packageWholesalePath} element={<PackageWholesaleDetailsView />} />
                <Route path={NavigationPath.PaymentSuccess} element={<PaymentSuccess />} />
                <Route path={NavigationPath.OperationSuccess} element={<OperationSuccess />} />
                <Route path={NavigationPath.PaymentFailure} element={<PaymentFailure />} />
                <Route path={NavigationPath.PaymentProcessing} element={<PaymentProcessing />} />
                <Route
                  path={NavigationPath.ZooBorysewCodesDeactivation}
                  element={<ZooBorysewCodesDeactivationView />}
                />
                <Route path={NavigationPath.SuntagoCodesDeactivation} element={<SuntagoCodesDeactivationView />} />
                <Route path={NavigationPath.AcquisitionOfConsents} element={<AcquisitionOfConsentsView />} />
                <Route path={NavigationPath.BookingOptions} element={<BookingOptionsView />} />
                <Route path={NavigationPath.RodoAgreements} element={<RodoAgreementsView />} />
                <Route path={NavigationPath.RodoAgreementsSuccess} element={<RodoAgreementsSuccessView />} />
                <Route path={NavigationPath.RodoAgreementsConfirmation} element={<RodoAgreementsConfirmationView />} />
                <Route path={NavigationPath.BocianVoucher} element={<BocianVoucherView />} />
                <Route path={NavigationPath.HolidayBenefitsEmployer} element={<HolidayBenefitView />} />
                <Route path={NavigationPath.HolidayBenefitsEmployee} element={<HolidayBenefitEmployeeView />} />
                <Route path={NavigationPath.HolidayBenefitInvitation} element={<HolidayBenefitInvitationView />} />
                <Route path={NavigationPath.EndlessHoliday} element={<EndlessHolidayDetailsView />} />
                <Route path={NavigationPath.EndlessHolidayConfirmation} element={<EndlessHolidayConfirmationView />} />
                <Route path={NavigationPath.NotFound} element={<NotFound />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </AppContainer>
          </BrowserRouter>
        </StartupView>
      </Provider>
    )
  }
}
