import { EndlessHolidayFormInputProduct } from '@modules/endless-holiday/details'

interface Response {
  gastroCardProducts: EndlessHolidayFormInputProduct[]
  subscriptionProducts: EndlessHolidayFormInputProduct[]
}

export const useEndlessHolidayAddedProducts = (products: EndlessHolidayFormInputProduct[]): Response => {
  const { subscriptionProducts, gastroCardProducts } = products.reduce(
    (acc, product) => {
      if (product.product.kind === 'subscription') {
        return { ...acc, subscriptionProducts: [...acc.subscriptionProducts, product] }
      } else if (product.product.kind === 'gastro_card_product_package_endless_holiday') {
        return { ...acc, gastroCardProducts: [...acc.gastroCardProducts, product] }
      }
      return acc
    },
    { gastroCardProducts: [], subscriptionProducts: [] },
  )

  return {
    gastroCardProducts,
    subscriptionProducts,
  }
}
