import * as React from 'react'
import { useApiRequest } from '@hooks/use-form-request'
import { AppHeaderSection } from '@components/app-header-section'
import { PaymentActionRow } from '@components/payments/payment-action-row'
import { AppFooter } from '@components/app-footer'
import { selectAppDataUrls } from '@store/selectors/app-data-selector'
import { useSelector } from 'react-redux'
import { usePaymentSelection } from '@hooks/use-payment-selection'
import { ProductKinds } from '@components/payments/payment-selection-view'
import { useScrollIntoView } from '@hooks/use-scroll-into-view'
import { useProductDetails } from '@hooks/use-product-details'
import { EndlessHolidayConfirmationHeaderSectionContent } from '@modules/endless-holiday/confirmation/confirmation-header-section-content'
import { EndlessHolidayConfirmationDetails } from '@modules/endless-holiday/models'
import { asDecimal } from '@helpers/utils'
import { EndlessHolidayConfirmationDetailsContent } from '@modules/endless-holiday/confirmation/confirmation-details-content'
import { EndlessHolidayConfirmationSideCard } from '@modules/endless-holiday/confirmation/side-card'
import { ResortImages } from '@modules/package-wholesale/details'

export const EndlessHolidayConfirmationView = (): React.ReactNode => {
  const { product_packages } = useSelector(selectAppDataUrls)

  const { PageWrapper, productDetails } = useProductDetails<EndlessHolidayConfirmationDetails>(product_packages.details)

  const billSectionRef = React.useRef<HTMLDivElement>(null)

  const { showPaymentSelectionView } = usePaymentSelection()
  const { scrollTo } = useScrollIntoView()

  const { isLoading: isSaving, action: onSubmit } = useApiRequest(async () => {
    if (!productDetails) return
    showPaymentSelectionView(productDetails.token, ProductKinds.ENDLESS_HOLIDAY)
  })

  const isPaid = asDecimal(productDetails?.required_payment).lte(0)
  const submitDisabled = !productDetails || isPaid

  return (
    <PageWrapper>
      <AppHeaderSection images={ResortImages} imageDescription="pakiety prywatne">
        {productDetails && (
          <EndlessHolidayConfirmationHeaderSectionContent
            onScrollToBill={scrollTo(billSectionRef)}
            productDetails={productDetails}
          />
        )}
      </AppHeaderSection>
      <div className="container app-header-section__after-content">
        <div className="row gx-xl-5">
          <div className="card col-lg-9 col-12 py-4  card-shadow border-0 overflow-hidden">
            <form onSubmit={onSubmit}>
              {productDetails && (
                <EndlessHolidayConfirmationDetailsContent
                  productDetails={productDetails}
                  ref={billSectionRef}
                  onScrollToBill={scrollTo(billSectionRef)}
                />
              )}
              <hr className="mx-n5" />

              <PaymentActionRow
                isPaymentDisabled={submitDisabled}
                isLoading={isSaving}
                className="mt-4"
                paymentDate={isPaid ? undefined : productDetails?.required_payment_date}
                amount={productDetails?.required_payment ?? 0}
              />
            </form>
          </div>
          {productDetails && <EndlessHolidayConfirmationSideCard productDetails={productDetails} isPaid={isPaid} />}
        </div>
      </div>
      <AppFooter />
    </PageWrapper>
  )
}
