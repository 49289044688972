import * as React from 'react'
import { Accordion } from 'react-bootstrap'
import { SubscriptionKind } from '@modules/package-wholesale/models'
import clsx from 'clsx'
import { declinateSubscriptionAmount } from '@modules/endless-holiday/helpers'
import { useFormContext, useWatch } from 'react-hook-form'
import { EndlessHolidayFormInputs } from '@modules/endless-holiday/details'
import { ACCOMMODATION_TYPES } from '@helpers/consts'

interface Props {
  icon?: React.ReactNode
  title: React.ReactNode
  subtitle: React.ReactNode
  children: React.ReactNode
  variant?: SubscriptionKind
  isDefaultExpanded?: boolean
  year: string
}

export const EndlessHolidayDetailsProductsYearSelection = ({
  year,
  icon,
  title,
  subtitle,
  children,
  variant,
  isDefaultExpanded = false,
}: Props): React.ReactNode => {
  const { control } = useFormContext<EndlessHolidayFormInputs>()

  const [addedProducts, accommodation] = useWatch({ control, name: ['products', 'accommodation'] })
  const productsAddedForYearAmount = addedProducts.reduce((acc, curr) => {
    const isProductForYear = curr.product.stay_in_years?.includes(parseInt(year, 10))

    const isHouseAccommodation = ACCOMMODATION_TYPES.HOUSES.includes(curr.product.accommodation_type)
    const isProductForAccommodation = isHouseAccommodation ? accommodation === 'house' : accommodation === 'apartment'

    if (curr.product.kind === 'subscription' && isProductForYear && isProductForAccommodation) {
      return acc + curr.amount
    }

    return acc
  }, 0)

  return (
    <Accordion className="endless-holiday__accordion" {...(isDefaultExpanded && { defaultActiveKey: '0' })}>
      <Accordion.Item eventKey="0" className={clsx({ [`is-${variant}`]: variant })}>
        <Accordion.Header>
          <div className="d-flex flex-column flex-lg-row align-items-center w-100">
            {icon && <div className="col-md-1 col-3">{icon}</div>}
            <div className="d-flex flex-column justify-content-center align-items-start">
              {title}
              {subtitle}
            </div>
            {!!productsAddedForYearAmount && (
              <div className="badge-success font-xs ms-lg-auto me-lg-5 text-nowrap mt-3 mt-lg-0">
                {declinateSubscriptionAmount(productsAddedForYearAmount)}
              </div>
            )}
          </div>
        </Accordion.Header>
        <Accordion.Body>
          <section className="col-12 col-md-10">{children}</section>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}
