export enum NavigationPath {
  PackageWholesaleDetails = '/pakiety-hurtowe/:token/',
  GastroCardDetails = '/podwojna-radosc/:token/',
  GastroCouponDetails = '/kupon-gastronomiczny/:token/',
  GastroCardBoostDetails = '/doladowanie-smaku/:token/',
  PaymentSelectionView = '/:kind/:token/sposob-platnosci/',
  PaymentFailure = '/:kind/:token/platnosc-blad/',
  PaymentSuccess = '/:kind/:token/platnosc-sukces/',
  OperationSuccess = '/:kind/:token/sukces',
  PaymentProcessing = '/payment-processing/',
  VipDetails = '/pakiet-vip/:token/',
  SubscriptionDetails = '/pakiet-prywatny/:token/',
  SubscriptionImprovement = '/super-start/:token/',
  ReservationDetails = '/podsumowanie-rezerwacji/:token/',
  SubscriptionContractDetailsFallback = '/go-holiday/:token/',
  SubscriptionContractDetails = '/subskrypcja/:token/',
  GoHolidayDetails = '/go-holiday/:token/',
  SubscriptionWithBenefitsDetails = '/pakiet-korzysci/:token/',
  SubscriptionWithBenefits20Details = '/pakiet-korzysci-20/:token/',
  ZooBorysewCodesDeactivation = '/dezaktywacja-kodow-zoo-borysew/',
  SuntagoCodesDeactivation = '/dezaktywacja-kodow-suntago/',
  AcquisitionOfConsents = '/aktualizacja-danych/:token/',
  BookingOptions = '/opcje-rezerwacji/:token/',
  Home = '/',
  RodoAgreements = '/zgody/:slug/',
  RodoAgreementsSuccess = '/zgody/:slug/sukces/',
  RodoAgreementsConfirmation = '/zgody/:token/:device/potwierdzenie/',
  NotFound = '/nie-znaleziono/',
  BocianVoucher = '/vouchery-bocian/',
  HolidayBenefitsEmployer = '/holiday-benefit/pracodawca/',
  HolidayBenefitsEmployee = '/holiday-benefit/pracownik/',
  HolidayBenefitInvitation = '/holiday-benefit/zaproszenie/:token/',
  EndlessHoliday = '/wakacje-w-nieskonczonosc/zaproszenie/:token/',
  EndlessHolidayConfirmation = '/wakacje-w-nieskonczonosc/potwierdzenie/:token/',
}

export enum OfferNavigationPath {
  PackageWholesaleDetails = '/:token/',
}
