import * as React from 'react'
import clsx from 'clsx'
import {
  EndlessHolidayConfirmationDetails,
  EndlessHolidayConfirmationSubscription,
} from '@modules/endless-holiday/models'
import { getYear } from 'date-fns'
import { parseISODate } from '@helpers/date-formatter'
import { ACCOMMODATION_TYPES } from '@helpers/consts'
import { formatPrice, formatPriceShort } from '@helpers/price-helper'
import { EndlessHolidayConfirmationBillGastroCards } from '@modules/endless-holiday/confirmation/confirmation-bill-gastro-cards'

interface Props {
  productDetails: EndlessHolidayConfirmationDetails
}

export const EndlessHolidayConfirmationBill = React.forwardRef(
  ({ productDetails }: Props, ref: React.Ref<HTMLDivElement>): React.ReactNode => {
    const groupedByYears = productDetails.subscriptions.reduce((acc, subscription) => {
      const subscriptionYear = getYear(parseISODate(subscription.expire_after))
      if (acc[subscriptionYear]) {
        return { ...acc, [subscriptionYear]: [...acc[subscriptionYear], subscription] }
      }
      return { ...acc, [subscriptionYear]: [subscription] }
    }, {})

    const getSubscriptionName = (subscription: EndlessHolidayConfirmationSubscription) => {
      const accommodationType = ACCOMMODATION_TYPES.HOUSES.includes(subscription.accommodation_type)
        ? 'Domek'
        : 'Apartament'

      const variant = {
        gold: 'Pakiet złoty',
        silver: 'Pakiet srebrny',
        brown: 'Pakiet brązowy',
      }[subscription.kind]

      return `${accommodationType} - ${variant} ${
        subscription.number_of_codes > 1 ? `x${subscription.number_of_codes} ` : ''
      }`
    }

    const groupedList = Object.entries(groupedByYears) as [string, EndlessHolidayConfirmationSubscription[]][]

    return (
      <div>
        <div ref={ref} className="bg-light-gray mx-n4 my-n3 p-4">
          <p className="text-gold fw-bold font-xl">Całkowity koszt zamówienia</p>
          {groupedList.map(([year, subscriptions], index) => (
            <div key={year}>
              <p className="text-secondary fw-bold mb-1 font-lg">W roku {year}</p>
              <ul className="list-unstyled mb-0">
                {subscriptions.map((subscription: EndlessHolidayConfirmationSubscription, index: number) => (
                  <li key={subscription.id} className={clsx({ 'mb-3': index < subscriptions.length - 1 })}>
                    <div className="text-darker-gray d-flex justify-content-between">
                      <div className="fw-bold">{getSubscriptionName(subscription)}</div>
                      <div className="fw-bold font-lg">{formatPriceShort(subscription.price_brutto)}</div>
                    </div>
                    {Array.from({ length: subscription.number_of_codes }).map((_, index) => (
                      <div key={index} className="text-muted d-flex justify-content-between ms-3 font-500">
                        <div>Kaucja zwrotna do przyszłej rezerwacji:</div>
                        <div>+ {formatPriceShort(subscription.deposit_amount)}</div>
                      </div>
                    ))}
                  </li>
                ))}
              </ul>
              <EndlessHolidayConfirmationBillGastroCards gastroCards={productDetails.gastro_cards} year={year} />
              {index < groupedList.length - 1 && <hr className="mx-n5" />}
            </div>
          ))}
        </div>
        <hr className="mx-n5" />
        <div className="font-xl d-flex justify-content-between align-items-center">
          <p className="text-darker-gray fw-bold mb-0">Do zapłaty łącznie:</p>
          <div className="fw-bold text-danger">{formatPrice(productDetails.required_payment)}</div>
        </div>
      </div>
    )
  },
)
