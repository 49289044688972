import * as React from 'react'
import { EndlessHolidayDetailsProductsYearSelection } from '@modules/endless-holiday/details/products-selection/product-year-accordion'
import { ProductVariant } from '@modules/endless-holiday/details/products-selection/product-variant/product-variant'
import { ProductVariantOptions } from '@modules/endless-holiday/details/products-selection/product-variant/options/product-variant-options'
import { EndlessHolidayProduct } from '@modules/endless-holiday/models'
import { ACCOMMODATION_TYPES } from '@helpers/consts'
import { useFormContext, useWatch } from 'react-hook-form'
import { EndlessHolidayFormInputs } from '@modules/endless-holiday/details'

const InitialGroupedProducts = {
  gold: { house: null, apartment: null },
  silver: { house: null, apartment: null },
  brown: { house: null, apartment: null },
}

interface Props {
  year: string
  products: EndlessHolidayProduct[]
  gastroProducts: EndlessHolidayProduct[]
  showDiscountedPrice: boolean
}

export const ProductsVariantsYear = ({
  products,
  year,
  gastroProducts,
  showDiscountedPrice,
}: Props): React.ReactNode => {
  const { control } = useFormContext<EndlessHolidayFormInputs>()

  const selectedAccommodationType = useWatch({ control, name: 'accommodation' })

  const grouped = products.reduce((acc, product) => {
    const accommodationType = ACCOMMODATION_TYPES.HOUSES.includes(product.accommodation_type) ? 'house' : 'apartment'

    return {
      ...acc,
      [product.subscription_kind]: { ...acc[product.subscription_kind], [accommodationType]: product },
    }
  }, InitialGroupedProducts)

  const gold = grouped.gold[selectedAccommodationType]
  const silver = grouped.silver[selectedAccommodationType]
  const brown = grouped.brown[selectedAccommodationType]

  return (
    <EndlessHolidayDetailsProductsYearSelection
      year={year}
      title={<h5 className="font-500 font-xxl mx-auto mx-lg-0 ">W roku {year}</h5>}
      subtitle={
        <span className="text-muted col-10 col-lg-12 text-center text-lg-start mx-auto mx-lg-0">
          {selectedAccommodationType === 'house'
            ? 'Każdy pakiet to 6-dniowy pobyt w 9-osobowym (max. 6 os. dorosłych) domku nad morzem lub w górach.'
            : 'Każdy pakiet to 6-dniowy pobyt w 4-osobowym (max. 3 os. dorosłe) apartamencie nad morzem lub w górach.'}
        </span>
      }
    >
      {gold && (
        <Variant
          showDiscountedPrice={showDiscountedPrice}
          variant="gold"
          gastroProducts={gastroProducts}
          subscriptionProduct={gold}
        />
      )}
      {silver && (
        <Variant
          showDiscountedPrice={showDiscountedPrice}
          variant="silver"
          gastroProducts={gastroProducts}
          subscriptionProduct={silver}
        />
      )}
      {brown && (
        <Variant
          showDiscountedPrice={showDiscountedPrice}
          variant="brown"
          gastroProducts={gastroProducts}
          subscriptionProduct={brown}
        />
      )}
    </EndlessHolidayDetailsProductsYearSelection>
  )
}

const Variant = ({ variant, subscriptionProduct, gastroProducts, showDiscountedPrice }) => {
  const variantData = {
    gold: {
      title: 'Pakiet złoty',
      icon: require('@assets/images/subscription-variants/gold.svg'),
      dates: 'od 26.08 do 07.07 ',
    },
    silver: {
      title: 'Pakiet srebrny',
      icon: require('@assets/images/subscription-variants/silver.svg'),
      dates: 'od 01.09 do 30.06*',
      expandedDatesText: '* z wyłączeniem Terminów Specjalnych',
    },
    brown: {
      title: 'Pakiet brązowy',
      icon: require('@assets/images/subscription-variants/bronze.svg'),
      dates: 'od 01.10 do 31.05* ',
      expandedDatesText: '* z wyłączeniem Terminów Specjalnych',
    },
  }[variant]

  return (
    <ProductVariant
      gastroProduct={gastroProducts[0]}
      subscriptionProduct={subscriptionProduct}
      variant={variant}
      showDiscountedPrice={showDiscountedPrice}
      title={<h5 className="fw-bolder subscription-variant__header is-brown">{variantData.title}</h5>}
      icon={<img src={variantData.icon} alt={variantData.title} height="40" />}
      subtitle={
        <div className="fw-normal text-muted mt-1 font-sm">
          <span className="d-lg-inline d-none">Do wykorzystania w terminie: </span>
          <span className="text-nowrap">{variantData.dates}</span>
        </div>
      }
    >
      <ProductVariantOptions subscriptionProduct={subscriptionProduct} gastroProducts={gastroProducts}>
        <span className="opacity-75 text-darker-gray font-xxs">{variantData.expandedDatesText}</span>
      </ProductVariantOptions>
    </ProductVariant>
  )
}
