import * as React from 'react'
import { CircleOutlineRadioButton } from '@components/form-controls/circle-outline-radio-button'
import { formatPriceShort } from '@helpers/price-helper'
import { EndlessHolidayProduct } from '@modules/endless-holiday/models'
import clsx from 'clsx'

interface GastroCardBoxProps {
  product: EndlessHolidayProduct
  onClick: () => void
  isChecked: boolean
  text: React.ReactNode
  inactiveLabel?: React.ReactNode
}

export const ProductVariantOptionBox = ({
  product,
  onClick,
  isChecked,
  text,
  inactiveLabel,
}: GastroCardBoxProps): React.ReactNode => (
  <div className="position-relative col-lg-6 col-12">
    <button
      className={clsx('btn-unstyled w-100', { 'pe-none opacity-25': inactiveLabel })}
      onClick={onClick}
      type="button"
    >
      <div className="rounded border d-flex align-items-center px-3 py-2 bg-lighter-gray">
        <CircleOutlineRadioButton checked={isChecked} />
        <div className="ms-3 text-start">
          <div className="font-lg fw-bold">{formatPriceShort(product.price_brutto)}</div>
          <span className="text-muted font-xs">{text}</span>
        </div>
      </div>
    </button>
    {inactiveLabel}
  </div>
)
