import { PaymentMethodSelection } from '@components/payments/payment-method-selection'
import { useApiRequest } from '@hooks/use-form-request'
import { commonObjectGet, commonObjectPost } from '@requests/basic-requests'
import * as React from 'react'
import { PaymentMethod, PaymentProvider } from '@models/app-data'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectAppData, selectAppDataStatus } from '@store/selectors/app-data-selector'
import { ReservationDetails } from '@modules/reservation/models'
import { useReservationPaymentData } from '@modules/reservation/details/use-reservation-payment-data'
import { FormProvider, useForm } from 'react-hook-form'
import { useAxiosErrorHandler } from '@hooks/use-axios-error-handler'
import { NavigationPath } from '@models/routes'
import { useGtmPaymentSelectionEvent } from '@hooks/gtm/use-gtm-payment-selection-event'
import { useGtmEcommerceBeginCheckoutGtmEvent } from '@hooks/gtm/ecommerce/use-begin-checkout-ecommerce-gtm-event'
import { SubscriptionDetails } from '@modules/subscription/models'
import { SubscriptionContractDetails } from '@modules/subscription-contract/models'
import { isGastroGenericProduct } from '@modules/gastro-generic/gastro-generic-data'
import { GastroProductKind } from '@modules/gastro-generic/models'
import { ContentLoader } from '@components/content-loader'

const INITIAL_PAYMENT_PROVIDERS: PaymentProvider[] = ['p24', 'payu']

interface ObjectDetails {
  kind?: string
  urls: { payment: string; sale: string }
}

export interface PaymentSelectionFormInputs {
  paymentProviderTerms: boolean
}

export enum ProductKinds {
  RESERVATION_DETAILS = 'podsumowanie-rezerwacji',
  ENDLESS_HOLIDAY = 'wakacje-w-nieskonczonosc',
  GASTRO_CARD = 'podwojna-radosc',
  PACKAGE_VIP = 'pakiet-vip',
  PACKAGE_WHOLESALE = 'pakiety-hurtowe',
  SUBSCRIPTIONS = 'pakiet-prywatny',
  SUBSCRIPTION_CONTRACT = 'subskrypcja',
  GO_HOLIDAY = 'go-holiday',
  SUBSCRIPTIONS_WITH_BENEFITS_20 = 'pakiet-korzysci-20',
  SUBSCRIPTIONS_WITH_BENEFITS = 'pakiet-korzysci',
  GASTRO_COUPON = 'kupon-gastronomiczny',
  GASTRO_CARD_BOOST = 'doladowanie-smaku',
  BOOKING_OPTIONS = 'opcje-rezerwacji',
  CODES_CONVERSION = 'konwersja-kodow',
}

type Params = 'token' | 'kind'

export const PaymentSelectionView = (): React.ReactNode => {
  const { token, kind } = useParams<Params>()

  const { urls, gastro_products, product_packages } = useSelector(selectAppData)
  const appDataStatus = useSelector(selectAppDataStatus)
  const handleAxiosFormErrors = useAxiosErrorHandler()

  const productsDetails = {
    'podsumowanie-rezerwacji': urls.reservation.details,
    'kupon-gastronomiczny': urls.gastro_card.details,
    'doladowanie-smaku': urls.gastro_card.details,
    'podwojna-radosc': urls.gastro_card.details,
    'pakiet-vip': urls.package_vip.details,
    'pakiety-hurtowe': urls.package_wholesale.subscription,
    'pakiet-prywatny': urls.subscription.details,
    'pakiet-korzysci': urls.subscription.details,
    'go-holiday': urls.subscription.details,
    'konwersja-kodow': urls.codes.details,
    'opcje-rezerwacji': urls.products.client_options_purchase,
    'super-start': urls.subscription.details,
    'wakacje-w-nieskonczonosc': urls.product_packages.details,
  }

  const isReservation = kind === 'podsumowanie-rezerwacji'

  const [productDetails, setProductDetails] = React.useState<ObjectDetails>()

  const { amount } = useReservationPaymentData((productDetails as ReservationDetails)?.prices?.payments_summary)
  const { sendAddPaymentInfoEvent } = useGtmPaymentSelectionEvent()

  useGtmEcommerceBeginCheckoutGtmEvent(
    3,
    kind,
    productDetails as SubscriptionDetails | SubscriptionContractDetails | undefined,
  )

  const methods = useForm<PaymentSelectionFormInputs>({
    defaultValues: { paymentProviderTerms: false },
  })

  const { isLoading, action: handlePayment } = useApiRequest(async (paymentMethod: PaymentMethod) => {
    if (!productDetails) return

    const payload = isReservation
      ? {
          amount,
          payment_method: paymentMethod.id,
          source: 'products-app',
          target: 'booking_advance',
        }
      : { payment_method: paymentMethod.id }

    try {
      sendAddPaymentInfoEvent(kind ?? '', paymentMethod, productDetails)

      const { url } = await commonObjectPost<{ url: string }>(productDetails.urls.payment, payload)
      document.location.href = url
    } catch (e) {
      if (e.response.status === 400 && e.response.data.redirect_url) {
        document.location.href = e.response.data.redirect_url
        return
      }

      if (e.response.status === 403) {
        document.location.href = `${kind}/${token}/`
      }

      handleAxiosFormErrors(e, methods.setError)
    }
  })

  const { action: fetch, isLoading: isFetchingProductDetails } = useApiRequest(async () => {
    if (!kind) return

    try {
      const details = await commonObjectGet<ObjectDetails>(productsDetails[kind].replace('replace-me', String(token)))
      if ('is_paid' in details && details.is_paid && !isReservation) {
        window.location.assign(NavigationPath.Home)
      }

      setProductDetails(details)
    } catch (e) {
      window.location.assign(NavigationPath.Home)
    }
  })

  React.useEffect(() => {
    fetch()
  }, [])

  const isGastroGeneric = isGastroGenericProduct(productDetails?.kind as GastroProductKind | undefined)

  const getPaymentProviders = () => {
    if (isGastroGeneric && productDetails?.kind && gastro_products[productDetails.kind]) {
      return gastro_products[productDetails?.kind].payment_providers
    }

    if (kind === ProductKinds.ENDLESS_HOLIDAY && productDetails?.kind === 'endless_holiday') {
      const productData = product_packages.find(product => product.kind === productDetails.kind)
      return productData?.payment_providers
    }

    return INITIAL_PAYMENT_PROVIDERS
  }

  return (
    <ContentLoader isLoading={isFetchingProductDetails || appDataStatus === 'loading'}>
      <FormProvider {...methods}>
        {productDetails && (
          <PaymentMethodSelection
            onClick={handlePayment}
            isLoading={isFetchingProductDetails || isLoading}
            paymentProviders={getPaymentProviders()}
          />
        )}
      </FormProvider>
    </ContentLoader>
  )
}
