import * as React from 'react'
import { ProductsVariantsYears } from '@modules/endless-holiday/details/products-selection/product-variant/product-variants-years'
import { EndlessHolidayDetails } from '@modules/endless-holiday/models'
import { FormError } from '@components/form-controls/form-error'
import { useFormContext } from 'react-hook-form'

interface Props {
  productDetails: EndlessHolidayDetails
}

export const ProductsVariantsSelection = ({ productDetails }: Props): React.ReactNode => {
  const { formState } = useFormContext()

  const productSelectionRef = React.useRef<HTMLParagraphElement>(null)

  React.useEffect(() => {
    if (formState.errors.products_selection) {
      productSelectionRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [formState.errors.products_selection])

  return (
    <div className="mt-5">
      <p className="font-xxl text-secondary text-center text-lg-start" ref={productSelectionRef}>
        Wybierz swoje <span className="font-500 text-nowrap">wakacje w nieskończoność:</span>
      </p>
      <FormError inputName="products_selection" className="font-lg mt-n1 mb-3" textClassName="font-lg" />
      <ProductsVariantsYears productDetails={productDetails} />
    </div>
  )
}
