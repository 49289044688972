import * as React from 'react'
import { EndlessHolidayConfirmationDetails } from '@modules/endless-holiday/models'
import { CardContentBase } from '@components/card-content-base'
import { CardContentBaseDataRow } from '@components/card-content-base-data-row'
import { asDecimal } from '@helpers/utils'
import { EndlessHolidayConfirmationBill } from '@modules/endless-holiday/confirmation/confirmation-bill'

interface Props {
  productDetails: EndlessHolidayConfirmationDetails
  onScrollToBill: () => void
}

export const EndlessHolidayConfirmationDetailsContent = React.forwardRef(
  ({ productDetails, onScrollToBill }: Props, ref: React.Ref<HTMLDivElement>): React.ReactNode => {
    return (
      <>
        <CardContentBase
          isProductPaid={asDecimal(productDetails.required_payment).lte(0)}
          client={productDetails.client}
          title="Podsumowanie Twojego zamówienia"
          seller={productDetails.seller ?? undefined}
          created={productDetails.created}
          onScrollToPayment={onScrollToBill}
          productInvoice={productDetails}
        >
          <CardContentBaseDataRow
            name="Numer zamówienia:"
            value={productDetails.number}
            nameClassName="text-gold text-center text-sm-start d-block fw-bold col-12 col-sm-4 text-center text-sm-start"
            valueClassName="text-darker-gray col-12 col-lg-auto text-center text-sm-start mb-3 mb-lg-0"
          />
        </CardContentBase>
        <hr className="mx-n5" />
        <EndlessHolidayConfirmationBill productDetails={productDetails} ref={ref} />
      </>
    )
  },
)
